import React from 'react'

import * as styles from './masthead.scss'

const Masthead = ({ type, modifiers, headline, byline }) => {

  let modifierClasses = ''
  if (modifiers) {
    for (let modifier of modifiers) {
      let modifierClass = ' c-masthead--' + modifier
      modifierClasses = modifierClasses + modifierClass
    }
  }

  if (type === 'balloon') {
    return (
      <section
        className={
          'o-section c-masthead c-masthead--balloon' + (modifiers ? modifierClasses : '')
        }
      >
        <div className="c-masthead__overlay">
          <div className="c-masthead__top">
            <div className="o-title o-title--large c-masthead__title c-masthead__title--lead">
              <div className="o-block">
                <div className="o-inner">
                  <h2 className="o-title__headline c-masthead__headline" dangerouslySetInnerHTML={{ __html: headline}}></h2>
                  <p className="o-title__byline c-masthead__byline" dangerouslySetInnerHTML={{ __html: byline }}></p>
                </div>
              </div>
            </div>
          </div>
          <div className="c-masthead__bottom">
            <div className="c-masthead__object o-verticalFlex__item" id="balloon" />
          </div>
        </div>
        <div className="c-stroke c-masthead__stroke" />
      </section>
    )
  } else {
    return (
      <section
        className={
          'o-section c-masthead' + (modifiers ? modifierClasses : '')
        }
      >
        <div className="c-masthead__overlay">
          <div className="c-masthead__top">
            <div className="o-block">
              <div className="o-title o-title--large c-masthead__title c-masthead__title--lead">
                <div className="o-inner">
                  <h2 className="o-title__headline c-masthead__headline" dangerouslySetInnerHTML={{ __html: headline}}></h2>
                  <p className="o-title__byline c-masthead__byline" dangerouslySetInnerHTML={{ __html: byline }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

Masthead.defaultProps = {
  headline: `Hello 👋\nWe’re pilot`,
  byline: ``
}

export default Masthead
