import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import EmailForm from '../emailForm/emailForm'

import * as styles from './cta.scss'

const Cta = ({ modifiers, type, headline, byline, linkTo, linkText }) => {
  let typeformEmbed

  let openForm = () => {
    typeformEmbed.typeform.open()
  }

  let modifierClasses = ''
  if (modifiers) {
    for (let modifier of modifiers) {
      let modifierClass = ' c-cta--' + modifier
      modifierClasses = modifierClasses + modifierClass
    }
  }

  if (type === 'button') {
    return (
      <div className={'o-section c-cta' + (modifiers ? modifierClasses : '')}>
        <div className="o-block">
          <div className="c-cta__section c-cta__section--lead">
            <div className="o-title o-title--large c-cta__title">
              <div className="o-inner">
                <h2 className="o-title__headline c-cta__headline">
                  {headline}
                </h2>
                <p className="o-title__byline c-cta__byline">{byline}</p>
              </div>
            </div>
          </div>
          <div className="c-cta__section">
            <a
              className="o-button o-button--inputSubmit o-button--fill o-button--cta"
              href={linkTo}
            >
              <span className="o-button__label">{linkText}</span>
            </a>
          </div>
        </div>
      </div>
    )
  } else if (type === 'typeform') {
    return (
      <div className={'o-section c-cta' + (modifiers ? modifierClasses : '')}>
        <div className="o-block">
          <div className="c-cta__section c-cta__section--lead">
            <div className="o-title o-title--large c-cta__title">
              <div className="o-inner">
                <h2 className="o-title__headline c-cta__headline">
                  {headline}
                </h2>
                <p className="o-title__byline c-cta__byline">{byline}</p>
              </div>
            </div>
          </div>
          <div className="c-cta__section">
            <button
              className="o-button o-button--inputSubmit o-button--fill o-button--cta"
              onClick={openForm}
              style={{ cursor: 'pointer' }}
            >
              <span className="o-button__label">{linkText}</span>
            </button>
            <ReactTypeformEmbed
              popup
              autoOpen={false}
              url={linkTo}
              buttonText={linkText}
              style={{ top: 100 }}
              ref={tf => {
                typeformEmbed = tf
              }}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={'o-section c-cta' + (modifiers ? modifierClasses : '')}>
        <div className="o-block">
          <div className="c-cta__section c-cta__section--lead">
            <div className="o-title o-title--large c-cta__title">
              <div className="o-inner">
                <h2 className="o-title__headline c-cta__headline">
                  {headline}
                </h2>
                <p className="o-title__byline c-cta__byline">{byline}</p>
              </div>
            </div>
          </div>
          <div className="c-cta__section">
            <EmailForm linkTo={linkTo} linkText={linkText}></EmailForm>
          </div>
        </div>
      </div>
    )
  }
}

export default Cta
