import React from 'react'

import * as styles from './tourItem.scss'

const TourItem = ({ headline, modifier }) => (
  <div
    className={
      'o-section c-tour__item c-tourItem ' +
      (modifier ? 'c-tourItem--' + modifier : '')
    }
  >
    <div className="c-tourItem__inner">
      <div className="o-title o-title--large c-tourItem__title">
        <div className="o-block">
          <div className="o-inner">
            <h3 className="o-title__headline c-tourItem__headline">
              {headline}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div className="c-stroke c-tourItem__stroke" />
  </div>
)

export default TourItem
