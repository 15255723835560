import React, { Component } from 'react'

import * as styles from './emailForm.scss'

class EmailForm extends Component {

  constructor() {
    super();
    this.state = {
      email: '',
      name: '',
      message: ''
    };
  }

  handleEmailChange = evt => {
    this.setState({ email: evt.target.value })
  }

  handleNameChange = evt => {
    this.setState({ name: evt.target.value })
  }

  validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  canBeSubmitted() {
    const { email, name } = this.state;
    return this.validateEmail(email) && email.length > 0 && name.length > 0;
  }

  render(linktTo) {
    const isEnabled = this.canBeSubmitted();
    return (
      <form
        action={this.props.linkTo}
        method="POST"
        className="o-form"
      >
        <div className="c-emailForm">
          <div className="o-form__fields">

            <div className="o-form__inputGroup">
              <div className="o-inner">
                <label className="o-form__label">Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  required
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  className="o-form__input o-form__input--text c-emailForm__input"
                />
              </div>
            </div>

            <div className="o-form__inputGroup">
              <div className="o-inner">
                <label className="o-form__label">Email address</label>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  className="o-form__input o-form__input--email c-emailForm__input"
                />
              </div>
            </div>

            <div className="o-form__inputGroup">
              <div className="o-inner">
                <label className="o-form__label">Email address</label>
                <textarea
                  rows="4"
                  placeholder="Message (optional)"
                  name="message"
                  className="o-form__input o-form__input--textarea c-emailForm__input"
                />
              </div>
            </div>

            <div className="o-form__inputGroup">
              <div className="o-inner">
                <button
                  disabled={!isEnabled}
                  type="submit"
                  name="send"
                  value="Sign up"
                  className="o-button o-button--inputSubmit o-form__button c-emailForm__button"
                >
                  <span className="o-button__label c-emailForm__buttonInner">{this.props.linkText}</span>
                </button>
              </div>
            </div>

          </div>
        </div>
      </form>
    )
  }
}

export default EmailForm
